import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { getActiveStudent } from '@actions/checkout'

const EducationalSolutionsProducts = ({ line, student }) => {
  const hasVoucherTeacher = student.voucher.toLowerCase().startsWith('p')
  const teacherDiscounts = line.products
    .filter(product => product.has_custom_discount_teacher)
    .map((item) => item.custom_discount_teacher)

  const totalTeacherDiscount = line.products.reduce((total, product) => {
    if (product.has_custom_discount_teacher) {
      return total + product.custom_discount_teacher;
    }
    return total;
  }, 0);
  
  const averageTeacherDiscount = totalTeacherDiscount / teacherDiscounts.length;
  const price = line.products.reduce((total, product) => total + product.amount_discounted, 0)
  const totalAmount = line.products.reduce((total, product) => total + product.amount, 0)
  const isEcos = line.products.every(product => product.name.toLowerCase().includes('ecos '))

  return (
    <div className="educational-solutions">
      <div className="educational-solutions__images">
        {line.products.slice(0, 10).map((product, index) => (
          <img key={index} src={product.images.GraficoBig} alt="Imagem dos produtos" />
        ))}
        {line.products.length > 10 && (
          <span className="educational-solutions__omitted-quantity">+{line.products.length - 10}</span>
        )}
        <input type="checkbox" className="select-book__list__checkbox disabled" checked disabled />
      </div>
      <div className="educational-solutions__description">
        <div>
          {isEcos}
          {isEcos
            ? 'Ecos'
            : 'Educamos'
          }
        </div>
        {/* {hasVoucherTeacher && (
          <div>
            <span className="select-book__list__price--discount">
              de: {totalAmount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </span>
            <span className="select-book__list__discount-tag">
              {averageTeacherDiscount}
              % desconto
            </span>
          </div>
        )} */}
        <div>{student.study_year} - Livro do Aluno</div>
        <div>Por: {price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    student: getActiveStudent(state),
  }
}

export default connect(mapStateToProps)(EducationalSolutionsProducts)
